import React from 'react';
import Layout from "../components/public/layout"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCross, faCheckCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import SearchBar from "../components/public/searchBar"
import mainMap from '../images/main_map_rott.png'
import { MapContainer, TileLayer, Marker, Popup, ImageOverlay } from 'react-leaflet'
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import { Icon } from 'leaflet'
import { Link } from 'gatsby';

const Grave = (props) => {
    var grave = props.pageContext.graveData
    const formatDate = (date) => {
        let tempDate = new Date(date)
        if (tempDate.getDate() === 1 && tempDate.getMonth() === 0) 
            return tempDate.toLocaleDateString('pl-PL', { year: 'numeric' })
        return tempDate.toLocaleDateString('pl-PL', { day: 'numeric', month: 'numeric', year: 'numeric' })
    }
    const addYears = (date, years) => {
        const dateCopy = new Date(date);
        dateCopy.setFullYear(dateCopy.getFullYear() + years);
        return dateCopy;
    }
    const getStatus = (date) => {
        if (date == null)
            return (
                // <span><strong>Opłata grobu: b/d</strong></span>
                <span><strong> </strong></span>
            )
        let now = new Date()
        let finalDate = addYears(date, 20)
        if (finalDate.getFullYear() >= now.getFullYear())
            return (
                <span><FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: 10, color: '#218C74' }} /><strong> Grób opłacony</strong></span>
            )
        else
            return (
                // <span><FontAwesomeIcon icon={faMinusCircle} style={{ marginRight: 10, color: '#e74c3c' }} /> <strong>Prosimy o kontakt z kancelarią</strong></span>
                // <span><strong>Prosimy o kontakt z kancelarią</strong></span>
                <span><strong> </strong></span>
            )
    }

    const switchPhotos = (dir) => {
        let mainPhoto = document.querySelector('#main-photo')
        let secondaryPhoto = document.querySelector('#secondary-photo')
        let mainButton = document.querySelector('#to-left')
        let secondaryButton = document.querySelector('#to-right')
        if (dir === 'right') {
            mainPhoto.style.marginLeft = '-100%'
            secondaryPhoto.style.marginLeft = '-100%'
            mainButton.style.display = 'block'
            secondaryButton.style.display = 'none'
        }
        else if (dir === 'left') {
            mainPhoto.style.marginLeft = '0'
            secondaryPhoto.style.marginLeft = '0%'
            mainButton.style.display = 'none'
            secondaryButton.style.display = 'block'
        }
    }

    var markerIcon = null
    if (typeof window !== 'undefined') {
        markerIcon = new Icon({
            iconUrl: markerIconPng,
            iconSize: [25, 41],
            iconAnchor: [12, 41]
        })
    }

    const sectionNameToNumber = (sectionName) => {
        switch (sectionName) {
            case "I":
                return 1;
            case "II":
                return 2;
            case "III":
                return 3;
            case "IV":
                return 4;
            case "V":
                return 5;
            case "V/DZ":
                return 6;
            case "VI":
                return 7;
            default:
                return 1;
        }
    }
    return (
        <Layout>
            <Helmet title={`Grób - Rząd: ${grave.row} Pozycja: ${grave.position} | Parafia w Starych Skoszewach`} />
            <div className="container">

                <div className="left-col">
                    <div className="grave">
                        <span className='where' style={{ padding: 10 }}>
                            <Link to='/'>Strona Główna</Link>
                            <span className='where-arrow'>&gt;</span>
                            <Link to='/cmentarz'>Cmentarz</Link>
                            <span className='where-arrow'>&gt;</span>
                            <Link to={`/cmentarz/kwatera/${sectionNameToNumber(grave.sectionName)}`}>Kwatera {grave.sectionName}</Link>
                        </span>
                        <h3 style={{ padding: 10 }}>
                            Kwatera: <strong>{grave.sectionName}</strong> Rząd: <strong>{grave.row}</strong> Pozycja: <strong>{grave.position}</strong>
                        </h3>
                        <div className="grave-image">
                            {
                                grave.images.length === 0 ?
                                    <div style={{ textAlign: 'center', margin: '10px 0' }}><b>Brak zdjęcia</b></div> :
                                    grave.images.length === 1 ?
                                        <img src={`${process.env.GATSBY_API_URL}:8080/api/v1/noauth/image/filename/server_${grave.images[0].name}`} alt='' width='100%' height='100%' /> :
                                        <div className='gallery'>
                                            <button id='to-left' className='nav-gallery' style={{ left: 5, display: 'none' }} onClick={() => switchPhotos('left')}>
                                                <FontAwesomeIcon icon={faChevronLeft} />
                                            </button>
                                            <button id='to-right' className='nav-gallery' style={{ right: 5 }} onClick={() => switchPhotos('right')}>
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </button>

                                            <img id='main-photo' src={`${process.env.GATSBY_API_URL}:8080/api/v1/noauth/image/filename/server_${grave.images[0].name}`} alt='' width='100%' height='100%' style={{ transition: '0.2s' }} />
                                            <img id='secondary-photo' src={`${process.env.GATSBY_API_URL}:8080/api/v1/noauth/image/filename/server_${grave.images[1].name}`} alt='' width='100%' height='100%' style={{ transition: '0.2s' }} />
                                        </div>
                            }
                        </div>
                        <div className='people'>
                            <h3>
                                <span style={{ fontSize: 80 }} className='cross'>
                                    <strong><FontAwesomeIcon icon={faCross} /></strong>
                                </span>
                                <br /><br />
                                <strong className='s-p' style={{ fontSize: 32 }}>Ś.P.</strong>
                            </h3>
                            {
                                grave.people.map(person => {
                                    return (
                                        <div className="person">
                                            <strong>{person.firstName} {person.lastName}</strong><br />
                                            {person.birthDate === null ? null : <>{`ur. ${formatDate(person.birthDate)} r.`}<br /></>}
                                            {person.age === null ? null : <>{person.firstName.endsWith('a') ? <>żyła</> : <>żył</>} {`lat ${person.age}`}<br /></>}
                                            zm. {person.deathDate === null ? <>b/d</> : `${formatDate(person.deathDate)} r.`}
                                        </div>
                                    )
                                })
                            }

                            <h3 style={{ marginTop: 25 }}>
                                {getStatus(grave.dateOfPayment)}
                            </h3>
                        </div>
                        <h4 style={{ textAlign: 'center', padding: '1em', width: '100%' }}>Miejsce na cmentarzu:</h4>
                        <div className="map">
                            <MapContainer center={[(3 * grave.latitude + 51.853719) / 4, (3 * grave.longitude + 19.636496) / 4]} zoom={19.2} style={{ height: "100%", width: "100%" }} scrollWheelZoom={false}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    maxZoom={25}
                                />
                                <ImageOverlay
                                    url={mainMap}
                                    bounds={[[51.852450, 19.635040], [51.854930, 19.637900]]}
                                    opacity={1}
                                    zIndex={10}
                                />
                                <Marker position={[grave.latitude, grave.longitude]} icon={(!!markerIcon) ? markerIcon : null}>
                                    <Popup>
                                        Kwatera: <strong>{grave.sectionName}</strong><br /> Rząd: <strong>{grave.row}</strong><br /> Pozycja: <strong>{grave.position}</strong>
                                    </Popup>
                                </Marker>
                            </MapContainer>
                        </div>
                    </div>
                </div>
                <div className="right-col">
                    <SearchBar />
                </div>
            </div>
        </Layout>
    )
}

export default Grave;
